import React from 'react';
import { bool } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortNumericUpAlt, faUserPlus, faShareAlt, faFluxCapacitor,
} from '@fortawesome/pro-light-svg-icons';

import {
  List, ListItem, ListItemText, ListItemAvatar, Typography,
} from '@material-ui/core';
import useStyles from './styles';

const BronzeBenefits = ({ highlights = false, extraFeatures = false }) => {
  const styles = useStyles();

  return (
      <>
        {!highlights && <Typography variant="h5">
          Bronze Benefits
        </Typography>
        }
        <List className={styles.nomargin}>
          <ListItem>
            <ListItemAvatar>
              <FontAwesomeIcon icon={faSortNumericUpAlt} className={styles.bronzeAvatar}/>
            </ListItemAvatar>
            <ListItemText
              primary="1 loyalty card"
              secondary="Build and share a digital loyalty card with your customers for Apple Wallet and Google Pay" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <FontAwesomeIcon icon={faUserPlus} className={styles.bronzeAvatar}/>
            </ListItemAvatar>
            <ListItemText
            primary="500 active customers"
            secondary={`We appreciate you may have different customers at different
            times. We will do our best to ensure only the customers using your Loyalty Card are counted.`}
            />
          </ListItem>
          {extraFeatures && (
            <>
            <ListItem>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faShareAlt} className={styles.bronzeAvatar}/>
              </ListItemAvatar>
              <ListItemText
              primary="Share and Scan"
              secondary={`Your dashboard area with access to sharing and scanning your loyalty card
                to manage the loyalty of your customers.`}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faFluxCapacitor} className={styles.bronzeAvatar}/>
              </ListItemAvatar>
              <ListItemText
                primary="Access to future features"
                secondary={`Our dev team is always building new features, we want to make this the best
                  digital loyalty platform on the market. All new features will be made available to all current customers.`}
                />
            </ListItem>
            </>
          )}
        </List>
        </>
  );
};

BronzeBenefits.propTypes = { highlights: bool, extraFeatures: bool };

export default BronzeBenefits;
